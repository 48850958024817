import React, { useState } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import { css } from "@emotion/react"

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md"

const Variant = (props) => {
    const [open, setOpen] = useState(null)

    const {
        name,
        image,
        desc,
        start_price,
        popular,
        index,
    } = props

    const desc_paragraphs = desc && desc.split('\n').filter(p => p !== "")

    // console.log("Paragraphs", desc_paragraphs)

    const handleClick = () => {
        if ( open ) {
            setOpen(null)
        } else {
            setOpen(index)
            // console.log("desc p", desc_paragraphs)
        }
    }

    return (
        <div css={main}>
            {image.fluid && 
                <div css={variantImg}>
                    <Img fluid={image.fluid} />
                </div>
            }
            <div css={content}>
                <h3>{name}</h3>
                {desc_paragraphs && <p style={{marginBottom: "2px"}}>{desc_paragraphs[0]}</p>}
                {desc_paragraphs && desc_paragraphs.length > 1 &&
                    <div>
                        <button onClick={handleClick} css={btn}>Voir plus {open ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</button>
                        <div css={open ? desc_open : desc_close}>
                            {desc_paragraphs.slice(1).map( (d, index) => <p key={index+d}>{d}</p> )}
                        </div>
                    </div>
                }
                {start_price && <div css={price}><Link to="#contact"><span className="price__on">A partir de {start_price}.- CHF</span><span className="price__off">Demandez une offre !</span></Link></div>}
                {popular && <div css={badge}>{popular && "POPULAIRE"}</div>}
            </div>
        </div>
    )
}

export default Variant

const main = css`
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    margin-top: 20px;
    overflow: hidden;
    position: relative;
    
    @media screen and (min-width: 800px) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
`

const content = css`
    flex-grow: 1;

    h3 {
        font-size: 1.2rem;
    }

    p { 
        margin-bottom: 10px;
        font-size: .9rem;
    }
`

const btn = css`
    border: none;
    background-color: transparent;
    padding: 0;
    font-size: .9rem;
    margin: 0;
    color: #888;
    cursor: pointer;

    :focus {
        outline: none;
    }

    svg {
        margin-bottom: -3px;
    }
`

const desc_open = css`
    display: block;
    // background-color: #F6F6F6;
    color: #888;
    margin-top: 5px;
    border-radius: 5px;
    border: 1px solid #F6F6F6;
    padding: 20px 20px 10px;
`

const desc_close = css`
    display: none;
`

const variantImg = css`
    border-radius: 10px;
    overflow: hidden;
    width: 100px;
    margin-bottom: 10px;

    @media screen and (min-width: 800px) {
        margin-right: 20px;
        min-width: 150px;
        margin-bottom: 0;
    }
`

const price = css`
    margin-top: 10px;

    .price__off {
        display: none;
    }

    a {
        display: inline-block;
        padding: 5px 13px;
        border-radius: 5px;
        background-color: #ecf2ff;
        text-decoration: none;
        color: inherit;

        :hover {
            background-color: #FF4208;
            color: white;

            .price__on {
                display: none;
            }
            .price__off {
                display: block;
            }
        }
    }


 
`

const badge = css`
    position: absolute;
    top: 0;
    right: 0;
    padding: 6px 10px;
    background-color: #FF4208;
    color: white;
    font-size: .8rem;
    border-bottom-left-radius: 10px;
    font-family: 'Courier Prime', Courier, monospace;
`
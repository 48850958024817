import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SeO from "../components/seo"
import Category from "../components/category"
import Form from "../components/form"
import Header from "../components/header"
import Production from "../components/production"
import Variant from "../components/variant"
import ContactThumb from "../components/contactThumb"
import Embed from "../components/embed"
import Button from "../components/button"

import { css } from "@emotion/react"
import { MdThumbUp } from "react-icons/md"

const ProductTemplate = (props) => {
    // console.log(props)
    const lang = props.pageContext.lang
    const product = props.data.product.data
    const uid = props.data.product.uid
    const title = product.title.text
    const subtitle = product.subtitle.text
    const intro = product.desc
    const definition = product.definition.html
    const benefits = product.benefits
    const included = product.included
    const category = product.category.document
    const linked_products = props.data.linked_products.edges.map( edge => edge.node )
    const productions = props.data.productions.edges
    const variants = product.variants
    const video = product.category.document.data.video && product.category.document.data.video.html

    // console.log("PRODs", variants)

    return (
        <Layout>
            <SeO 
                title={`Agence ${title} à Lausanne, Suisse romande - Lyketil [DIGITAL LAB]`}
                description={intro}
                lang={lang}
                slug={props.uri}
            />

            <Header 
                title={title}
                subtitle={subtitle}
                intro={intro}
                local_seo
                cta_text="Demander une offre"
                cta_link={`#contact`}
                lang={lang}
            />

            <div className="bg--white">
                    <section css={definitionSection}>
                        <div className="container">
                            <span className="section-subtitle">Definition</span>
                            <h2>Votre agence <span>{title}</span> en Suisse romande</h2>
                            <div className="definition-content" dangerouslySetInnerHTML={{ __html: definition }} />

                            {video &&
                              <div style={{marginTop: "50px"}}>
                                <Embed embed={video}  />
                              </div>
                            }

                            {productions.length > 0 && 
                              <Button link={`#temoignages`} text={`Lire nos avis client.e.s`} icon={<MdThumbUp />} />
                            }
                        </div>
                    </section>
                
                    {variants && variants[0].variant_name.text &&
                        <section css={variantsSection}>   
                            <div className="container">
                              <span className="section-subtitle">Formules fréquentes</span>
                              <h2>Ces packs ont du succès à Lausanne, Genève, Sion, Fribourg, Montreux, Vevey...</h2>
                            </div>
                            <div className="container--wide">
                              <div>
                                  {variants.map( (variant, index) => (
                                      <Variant
                                          key={variant.variant_name.text}
                                          name={variant.variant_name.text}
                                          image={variant.variant_image}
                                          desc={variant.variant_desc}
                                          start_price={variant.start_price}
                                          popular={variant.popular}
                                          index={index+1}
                                      />
                                  ))}
                              </div>
                          </div>
                        </section>
                    }

                    <section css={benefitsSection} className="border-top">
                        <div className="container">
                            <span className="section-subtitle">Bénéfices</span>
                            <h2>Pourquoi nous faire appel pour votre <span>{title}</span> en romandie</h2>
                        </div>
                        <div className="container--wide">
                            <div className={benefits.length < 3 ? 'two-col-grid' : 'three-col-grid'}>
                                {benefits.map( (benefit, index) => (
                                    <div className="card--numbered" key={benefit.benefit_item_title.text}>
                                        <span className="card-number text-gradient">{index + 1}</span>
                                        <h3>{benefit.benefit_item_title.text}</h3>
                                        <p className="pale">{benefit.benefit_item_desc}</p>
                                    </div>
                                ))} 
                            </div>
                        </div>
                    </section>

                    {included[0].included_item_desc &&
                        <section css={includedSection} className="border-top">
                            <div className="container">                        
                                <span className="section-subtitle">Notre approche</span>
                                <h2>Intéressé.e ? Voilà comment nous pouvons procéder et ce qui peut être inclus dans le mandat</h2>
                                <div className="timeline">
                                    {included.map( (item, index) => (
                                        <div className="timeline-item" key={item.included_item_title.text}>
                                            <span className="timeline-number text-gradient">#{index + 1}</span>
                                            <h3>{item.included_item_title.text}</h3>
                                            <p>{item.included_item_desc}</p>
                                        </div>
                                    ))} 
                                </div>
                            </div>
                        </section>
                    }

                    {productions.length > 0 && 
                        <section css={portfolioSection} id="temoignages">
                            <div className="container">
                                <span className="section-subtitle">Réalisations</span>
                                <h2>Sélection de <span>{title}</span> en Suisse romande</h2>
                                <div>
                                    {productions.map( production => (
                                        <Production 
                                            lang={`fr`}
                                            key={production.node.uid}
                                            slug={production.node.uid}
                                            title={production.node.data.title.text}
                                            client={production.node.data.client.document && production.node.data.client.document.data.name.text}
                                            place={production.node.data.client.document && production.node.data.client.document.data.place}
                                            quotes={production.node.data.quotes}
                                            image={production.node.data.image}
                                        />
                                    ))}
                                </div>
                                <p style={{color: "white"}}><i>NB: Toutes nos réalisations "{title}" en Suisse romande ne figurent pas forcément sur cette page, certains de nos projets étant confidentiels / privés, ou tout simplement pas encore ajoutés à notre portfolio sur ce site. Nous pouvons cependant vous en partager plus de détails, nos références et témoignages client.e.s lors d'une prise de contact.</i></p>
                            </div>
                        </section>
                    }
            </div>

            <section id="contact">
                <div className="container">
                    <Form />
                </div>
            </section>

            <section css={parentCategory}>
                <div className="container">
                    <span className="section-subtitle">WHAT ELSE?</span>
                    <h2 className="section-header">Découvrez nos autres offres de {category.data.title.text} à Lausanne, Genève, Montreux, Sion, Neuchâtel, Bienne... </h2>
                    <Category 
                        image={category.data.image.fluid.src}
                        uid={category.uid}
                        index={category.data.product_index} 
                        title={category.data.title.text}
                        subtitle={category.data.subtitle.text}
                        desc={category.data.desc}
                        products={linked_products}
                        current_product={uid}
                    />
                </div>
            </section>

            <ContactThumb />
        </Layout>
    )
}

export default ProductTemplate

const sectionSpacing = css`
    padding-top: 90px;
    padding-bottom: 80px;
`

const definitionSection = css`
    ${sectionSpacing};

    div.definition-content {
        padding: 20px 20px 10px;
        border: 1px solid #EFEFEF;
        // background-color: #f7f7f7;
        border-radius: 15px;
        
        @media screen and (min-width: 600px) {
            margin: 0 -20px;
        }
    }

    p {
        font-size: 1.3rem;
        opacity: .75;
    }
`

const variantsSection = css`
    ${sectionSpacing};
    background-color: #ecf2ff;
`

const benefitsSection = css`
    ${sectionSpacing};
    background-color: #f7f7f7;
`

const includedSection = css`
    ${sectionSpacing};

    .timeline {
        margin-top: 40px;
    }
`

const portfolioSection = css`
    ${sectionSpacing};
    // background-image: linear-gradient(13deg,#ff4208 0%,#ff07a8 100%);
    background-color: #FF4208;

    h2, span {
        color: white;
    }

    h2 {
        margin-bottom: 40px;
    }
`

const parentCategory = css`
    border-top: 1px solid rgba(255, 255, 255, .18);
    margin-top: 66px;
    padding-top: 66px;
    padding-bottom: 11px;

    .section-subtitle {
        color: white;
    }

    h2.section-header { 
        color: white;
        margin-bottom: 30px;
    }
`

export const productTemplateQuery = graphql`
    query ProductTemplateQuery($slug: String!, $category: String!) {
        product: prismicProducts(uid: {eq: $slug}) {
            uid
            data {
              title {
                text
              }
              subtitle {
                text
              }
              desc
              definition {
                html
              }
              benefits {
                benefit_item_title {
                  text
                }
                benefit_item_desc
              }
              included {
                included_item_title {
                  text
                }
                included_item_desc
              }
              variants {
                variant_name {
                    text
                }
                variant_desc
                start_price
                variant_image {
                  fluid {
                    ...GatsbyPrismicImageFluid
                  }
                }
                popular
              }
              category {
                document {
                  ... on PrismicCategories {
                    uid
                    data {
                          title {
                            text
                          }
                          subtitle {
                            text
                          }
                          desc
                          video {
                            html
                          }
                          product_index
                          visible_on_homepage
                          image {
                            fluid(imgixParams: {q: 100}) {
                              src
                            }
                        }
                    }
                  }
                }
              }
            }
        }
        linked_products: allPrismicProducts(filter: {data: {category: {uid: {eq: $category}}}}) {
            edges {
              node {
                uid
                data {
                  title {
                    text
                  }
                }
              }
            }
        }
        productions: allPrismicProductions(filter: {data: {parent_products: {elemMatch: {linked_product: {uid: {eq: $slug}}}}}}) {
            edges {
              node {
                uid
                data {
                  title {
                    text
                  }
                  client {
                    document {
                      ... on PrismicClients {
                        data {
                          name {
                            text
                          }
                          place
                        }
                      }
                    }
                  }
                  quotes {
                    quote {
                      text
                    }
                    author
                    author_position
                  }
                  image {
                    fluid(imgixParams: {q: 100}, maxWidth: 400) {
                        ...GatsbyPrismicImageFluid
                    }
                  }
                }
              }
            }
        }
    }
`
import React from 'react'

import { css } from "@emotion/react"

const Form = (props) => {

    const handleClick = () => {
        if (typeof document !== `undefined`) {
            window.dataLayer.push({event: 'formBtnClicked'})
            // console.log("Form Btn Clicked")
        }
    }

    const handleSubmit = () => {
        if (typeof document !== `undefined`) {
            window.dataLayer.push({event: 'formSubmitted'})
            // console.log("Form Submitted")
        }
    }

    return (
    <div css={contactUs} className="form-wrapper" id="contact">
        <h2>Travaillons ensemble</h2>
        <form name={props.formName || "Contact"} data-netlify="true" method="post" netlify-honeypot="bot-field" action="/merci" id="netlify-form" onSubmit={handleSubmit}>
            <div className="form-container">
                <label htmlFor="first-name" className="field half-width half-width-left">
                    <span>Prénom</span>
                    <input id="first-name" type="text" name="first-name" aria-label="first-name" placeholder="Emilie" required />
                </label>

                <label htmlFor="last-name" className="field half-width half-width-right">
                    <span>Nom</span>
                    <input id="last-name" type="text" name="last-name" aria-label="last-name" placeholder="Duvalais" required />
                </label>

                <label htmlFor="email" className="field half-width half-width-left">
                    <span>Email</span>
                    <input id="email" type="email" name="email" aria-label="email" placeholder="emilie@duvalais.ch" required />
                </label>

                <label htmlFor="phone" className="field half-width half-width-right">
                    <span>Votre téléphone</span>
                    <input id="phone" type="phone" name="phone" aria-label="phone" placeholder="0791234567" required />
                </label>

                <label className="field full-width" htmlFor="message">
                    <span>Message</span>
                    <textarea id="message" type="text" name="message" aria-label="message" placeholder="Votre message" rows="4" required></textarea>
                </label>

                <input type="hidden" name="bot-field" aria-label="bot field" />
                <input type="hidden" name="form-name"  aria-label="form name" value={props.formName || "Contact"} />

                <div>
                    <button className="send-button" type="submit" aria-label="Envoyer" onClick={handleClick}>
                        Envoyer <span role="img" aria-label="camera">📮</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
    )
}

export default Form

const contactUs = css`
  margin-top: 100px;
`
import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import { css } from "@emotion/react"

import { MdPlace, MdDomain } from "react-icons/md"

const Production = (props) => {
    const {
        // lang,
        slug,
        title,
        client, 
        place,
        quotes,
        image,
    } = props

    return (
        <div css={main}>
            {image.fluid &&
                <div css={imageSide}>
                    <Img fluid={image.fluid} />
                </div>
            }
            <div css={metaSide}>
                <Link to={`/portfolio/${slug}`}><h3>{title}</h3></Link>
                {client && <div className="portfolio-meta"><MdDomain /> {client}</div>}
                {place && <div className="portfolio-meta"><MdPlace /> {place}</div>}
            </div>
            {quotes[0] && quotes[0].quote.text ?
                <div css={quoteSide}>
                    {quotes.map( (quote, index) => (
                        <div 
                            className={index > 0 ? "more-than-one-quote" : undefined}
                            key={quote.author}
                        >
                            <blockquote>{quote.quote.text}</blockquote>
                            <div className="quote-author"><span>{quote.author}</span>{quote.author_position}</div>
                        </div>
                    ))}
                </div>
                :
                <div css={quoteSide}>Témoignage client privé, référence disponible sur demande.</div>
            }
        </div>
    )
}

export default Production

const main = css`
    color: white;
    background-color: rgba(255, 255, 255, .18);
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 20px;
    display: block;
    
    @media screen and (min-width: 700px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    a, a:hover, a:visited, a:active {
        text-decoration: none;
        color: white;
    }

    h3 {
        font-size: 1.1rem;
        font-weight: 400;
        margin-top: 5px;
        margin-bottom 10px;
    }

    .portfolio-meta {
        font-size: .8rem;
        padding: 5px 10px;
        border-radius: 5px;
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 5px;
        background-color: rgba(255, 255, 255, .13);
    }
`

const imageSide = css`
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    max-height: 200px;

    @media screen and (min-width: 700px) {
        width: 20%;
        margin-right: 20px;
    }
`

const metaSide = css`
    width: 100%;

    @media screen and (min-width: 700px) {
        width: 33%;
        flex-grow: 1;
    }

    svg {
        margin-bottom: -2px;
    }
`

const quoteSide = css`
    width: 100%;
    font-family: 'Courier Prime', Courier, monospace;
    margin-top: 20px;

    @media screen and (min-width: 700px) {
        width: 33%;
        margin-top: 0;
        margin-left: 20px;
        padding-left: 20px;
        border-left: 1px solid rgba(255, 255, 255, .5);
    }

    div.more-than-one-quote {
        margin-top: 10px;
    }

    blockquote {
        margin: 0;
        padding: 0;
        font-size: .8rem;
    }

    .quote-author {
        margin-top: 5px;
        font-size: .8rem;
        opacity: .66;

        span {
            font-weight: 800;
            margin-right: 10px;
        }
    }
`
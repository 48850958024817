import React from "react"
import { Link } from "gatsby"

import { css } from "@emotion/react"

import { MdNavigateNext } from "react-icons/md"

const Category = (props) => {
    const {
        image,
        uid,
        index, 
        title,
        subtitle,
        desc,
        products,
        current_product
    } = props

    return (
        <div css={categoryCard} style={{
            backgroundImage: `url(${image})`,
          }}>
            <div css={wrapper}>
              <div className="category-card--main">
                <Link to={`/${uid}`}><h2>{index}. {title} <MdNavigateNext /></h2></Link>
                <h3>{subtitle}</h3>
                <p>{desc}</p>
              </div>
              {products &&
                <div className="category-card--products">
                  {products.map( product => (
                    <div key={product.uid}>
                      <Link 
                        to={`/${product.uid}`} 
                        css={productBox}
                        className={(current_product && current_product === product.uid) ? "product--current-viewed" : null}
                      >
                        {product.data.title.text} <MdNavigateNext />
                      </Link>
                    </div>
                  ))}
                </div>
              }
            </div>
        </div>
    )
}

export default Category


const wrapper = css`
  padding: 20px;
  background-color: rgba(255, 255, 255, .92);

  @media screen and (min-width: 600px) {
    padding: 50px;
    background-color: transparent;
  }
`

const categoryCard = css`
  border-radius: 25px;
  background-color: #f0f0f0;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  margin-bottom: 30px;

  @media screen and (min-width: 600px) {
    background-size: cover;
  }

  .category-card--main {
    max-width: 400px;

    a {
      text-decoration: none;
      color: inherit;

      &:hover {
        color: #FF4208;
      }
    }

    h2 {
      font-weight: 300;
      font-size: 1.3rem;
      line-height: 1.3rem;
      
      @media screen and (min-width: 600px) {
        font-size: 1.8rem;
      }

      svg {
        font-size: 1.5rem;
        margin-bottom: -4px;
      }
    }
  
    h3 {
      font-weight: 500;
      text-transform: uppercase;
      font-size: .8rem;
      margin-bottom: 10px;
      color: #aaaaaa;
      letter-spacing: 2px;
    }

    p {
      font-size: .9rem;
      margin-bottom: 20px;
      color: #888;
      max-width: 330px;

      @media screen and (min-width: 600px) {
        font-size: 1.2rem;
      }
    }
  }

  .category-card--products {
    max-width: 600px;

    div {
      margin: 0 0 5px;
      padding: 0;
    }
  }
`

const productBox = css`
  display: inline-block;
  background-color: #ffffff;
  border-radius: 5px 17px 17px 17px;
  overflow: hidden;
  padding: 5px 13px;
  max-width: 600px;
  text-decoration: none;
  color: #101d3f;
  font-size: .7rem;
  
  @media screen and (min-width: 600px) {
    font-size: .8rem;
    padding: 6px 12px 6px 15px;
  }

  &:hover {
    background-color: #FF4208;
    color: white;
  }

  svg {
    font-size: 1rem;
    margin-bottom: -3px;
  }

  &.product--current-viewed {
    background-color: #101d3f;
    color: white;
    cursor: not-allowed;
  }
`